/*------------------------------------------------------------------*/
/*-------------------------- LOGIN PAGE ----------------------------*/
/*------------------------------------------------------------------*/

body.login {
    background-image: url(../img/login-bg.jpg);
    background-size: cover;
    background-position: center;
    overflow: hidden;
} 

.login-form-container	{
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -257px 0 0 -157px;
    background: rgba(255, 255, 255, 0.6);
    padding: 18px 28px 10px;
    min-height: 515px;
    width: 315px;
    .logo {
        margin-bottom: 27px;
        background: transparent;
        float: none;
        margin-left: -10px;
    }
    .btn {
        margin-bottom: 0;
        text-transform: uppercase;
        display: block;
        width: 100%;
        height: 41px;
    }
    .checkbox {
        margin-top: 35px;
    }
    .password-reset {
    	color: $orange;
        margin-bottom: 64px;
        display: block;
        margin-top: 48px;
        font-size: 12px;
        letter-spacing: 0px;
    }
    label {
	    font-size: 13px;
        &.error {
            position: static;
            text-align: center;
            width: 100%;
        }
	}
}

.password-reset-form {
	visibility: hidden;
	opacity: 0;
    height: 0;
}
.input-error {
    color: #ff0000;
    margin-bottom: 30px;
    display: block;
    font-size: 13px;
    text-align: center;
    opacity: 0;
    height: 0;
    @include transition(0.3s);
}

.reset-form {
	.login-form {
	    opacity: 0;
	    visibility: hidden;
	    height: 0;
	}

	#password-reset-form {
	    opacity: 1;
	    visibility: visible;
	    height: auto;
	}

}

#success-text {
    text-align: center;
    margin-bottom: 20px;
    color: #ff0000;
    /*height: 0;*/
    overflow: hidden;
}