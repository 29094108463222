/*------------------------------------------------------------------*/
/*--------------------------- VARIABLES ----------------------------*/
/*------------------------------------------------------------------*/

$orange: #F39769;
$darkOrange: #fa661d;
$blue: #00b0cb;
$darkBlue: #26344b;
$red: #fa3c1d;
$grey: #99a2af;
$border_red: #ff0f0f;

/*------------------------------------------------------------------*/
/*----------------------------- MIXINS -----------------------------*/
/*------------------------------------------------------------------*/

@mixin transition($val) {
    -moz-transition: $val;
    -webkit-transition: $val;
    transition: $val;  
}

@mixin transform($val) {
    -moz-transform: $val;
    -webkit-transform: $val;
    transform: $val;  
}