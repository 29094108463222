/*------------------------------------------------------------------*/
/*--------------------------- VARIABLES ----------------------------*/
/*------------------------------------------------------------------*/
/*------------------------------------------------------------------*/
/*--------------------------- VARIABLES ----------------------------*/
/*------------------------------------------------------------------*/
/*------------------------------------------------------------------*/
/*----------------------------- MIXINS -----------------------------*/
/*------------------------------------------------------------------*/
/*------------------------------------------------------------------*/
/*-------------------------- TYPOGRAPHY ----------------------------*/
/*------------------------------------------------------------------*/
/*------------------------------------------------------------------*/
/*----------------------------- FONTS ------------------------------*/
/*------------------------------------------------------------------*/
html {
  height: 100%; }

body {
  font-family: Open sans, Arial, Tahoma;
  font-size: 13px;
  color: #323232;
  background: #26344b; }

.page-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 27px;
  margin-bottom: 17px; }

.characters {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background: #fff;
  z-index: 3;
  border: 1px solid #cbcbcb;
  margin-top: 10px;
  padding: 6px;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2); }
  .characters + .characters {
    display: none; }
  .characters ul {
    padding: 0; }
    .characters ul li {
      list-style: none;
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 1px solid #cbcbcb;
      margin: 3px;
      text-align: center;
      line-height: 30px;
      font-size: 21px;
      cursor: pointer;
      transition: 0.2s;
      position: relative;
      background: #fff; }
      .characters ul li:hover {
        background: #00B0CB;
        color: #fff;
        transform: scale(1.2);
        z-index: 1; }

/*------------------------------------------------------------------*/
/*---------------------------- LAYOUT ------------------------------*/
/*------------------------------------------------------------------*/
/*------------------------------------------------------------------*/
/*-------------------------- MENU SPRITE ---------------------------*/
/*------------------------------------------------------------------*/
[class*=menu-icon-]:before {
  content: '';
  background-image: url(../img/sprite.png);
  display: inline-block;
  width: 40px;
  height: 26px; }

.menu-icon-home:before {
  background-position: 0 0;
  width: 24px; }

.menu-icon-wachtlijst:before {
  background-position: -50px 0;
  width: 24px; }

.menu-icon-active-deelnemers:before {
  background-position: -100px 0;
  width: 24px; }

.menu-icon-niet-active-deelnemers:before {
  background-position: -150px 0;
  width: 24px; }

.menu-icon-uitgeschereven-deelnemers:before {
  background-position: -200px 0;
  width: 33px; }

.menu-icon-lesmateriaal-lestijd:before {
  background-position: -250px 0;
  width: 24px; }

.menu-icon-instructeur:before {
  background-position: -300px 0;
  width: 24px; }

.menu-icon-deelnemer-invoeren:before {
  background-position: -350px 0;
  width: 24px; }

/*------------------------------------------------------------------*/
/*---------------------------- NAV BAR -----------------------------*/
/*------------------------------------------------------------------*/
.logo {
  float: left;
  background: #fff;
  padding: 6px 12px 6px 8px; }

.nav-bar {
  background: #eef1f5;
  box-shadow: 0 0 12.9px 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  z-index: 5; }
  .nav-bar:after {
    display: table;
    content: '';
    clear: both; }
  .nav-bar a {
    color: #99a2af;
    text-decoration: none; }

.breadcrumbs {
  float: left; }
  .breadcrumbs ul {
    padding-left: 22px;
    margin-bottom: 9px; }
  .breadcrumbs li {
    display: inline-block;
    padding: 18px 0 3px; }
    .breadcrumbs li .fa {
      margin: 0 12px;
      color: #000000; }
    .breadcrumbs li a {
      font-size: 13px;
      letter-spacing: -0.5px; }

.account-nav {
  float: right;
  margin: 7px 29px 5px 0; }
  .account-nav ul {
    margin-bottom: 0;
    padding: 0; }
  .account-nav li {
    display: inline-block; }
    .account-nav li a {
      font-size: 14px;
      font-weight: 600;
      border-right: 1px solid;
      padding: 12px 20px 6px 15px;
      display: inline-block;
      vertical-align: middle; }
    .account-nav li .fa {
      font-size: 20px;
      font-weight: 400; }
      .account-nav li .fa:before {
        -moz-transition: 0.3s;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
      .account-nav li .fa:hover:before {
        color: #646C77; }

/*------------------------------------------------------------------*/
/*-------------------------- NAV SIDEBAR ---------------------------*/
/*------------------------------------------------------------------*/
.nav-sidebar {
  float: left;
  width: 143px;
  background: #26344b;
  margin-top: 50px;
  margin-right: -100%; }

.menu-sidebar {
  padding: 0;
  text-align: center; }
  .menu-sidebar li {
    padding-top: 11px;
    position: relative;
    background: #26344B;
    list-style: none; }
    .menu-sidebar li:hover {
      background: #eef1f5; }
      .menu-sidebar li:hover a {
        color: #081730; }
    .menu-sidebar li.active {
      background: #eef1f5; }
      .menu-sidebar li.active a {
        color: #081730; }
    .menu-sidebar li:hover > ul {
      opacity: 1;
      visibility: visible; }
    .menu-sidebar li ul {
      position: absolute;
      left: 100%;
      top: 0;
      padding: 0;
      width: 143px;
      z-index: 5;
      opacity: 0;
      visibility: hidden;
      -moz-transition: 0.3s;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .menu-sidebar li ul li {
        padding: 0;
        background: #eef1f5; }
        .menu-sidebar li ul li:hover, .menu-sidebar li ul li a:hover {
          background: #26344B;
          color: #eef1f5; }
    .menu-sidebar li a {
      display: block;
      color: #b9c8e4;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.6px;
      padding: 10px 19px 14px;
      border-bottom: 1px solid #4d5d7c;
      text-decoration: none; }
    .menu-sidebar li span {
      display: block; }

/*------------------------------------------------------------------*/
/*----------------------------- BLOCKS -----------------------------*/
/*------------------------------------------------------------------*/
#content {
  float: left;
  margin-left: 0px;
  padding-top: 50px;
  width: 100%; }

.container {
  width: auto;
  padding: 12px 26px 10px 19px;
  background: #eef1f5;
  margin-left: 143px;
  min-height: 700px; }

.block {
  background-color: #fff;
  padding: 13px 13px 9px;
  margin-bottom: 13px; }
  .block:after {
    content: '';
    display: table;
    clear: both; }

.bordered {
  border-left: 3px solid #00b0cb; }
  .bordered.border-orange {
    border-left-color: #F39769; }

.space {
  display: block;
  /*clear: both;*/
  height: 50px; }

.h20 {
  height: 20px; }

.h70 {
  height: 70px; }

.h10 {
  height: 10px; }

/*------------------------------------------------------------------*/
/*----------------------------- FILTER -----------------------------*/
/*------------------------------------------------------------------*/
.active-filter a {
  padding: 6px 22px 8px 11px;
  display: inline-block;
  border: 1px solid;
  border-radius: 30px;
  margin-right: 9px;
  border-color: #fa661d;
  text-decoration: none;
  color: #000;
  font-size: 13px; }

.active-filter a i {
  margin-right: 7px;
  color: #fa661d; }

.filter-options {
  margin-bottom: 19px; }
  .filter-options .active-option {
    padding: 9px 17px; }
  .filter-options .select {
    width: 192px; }
  .filter-options input[type='text'] {
    padding: 8px 16px;
    margin-right: 0; }

.filter-buttons .btn {
  margin-bottom: 14px; }

/*------------------------------------------------------------------------*/
/*----------------------------- CALENDAR ---------------------------------*/
/*------------------------------------------------------------------------*/
.datepicker.datepicker-dropdown {
  border-radius: 0;
  box-shadow: 5px 3px 0 0 rgba(0, 0, 0, 0.1);
  margin-top: 300px; }

.datepicker table tr td.day:hover {
  border-radius: 0; }

.datepicker table tr td.active.active {
  border-radius: 0;
  background: #00B0CB; }

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #D9D9D9 transparent; }

.datepicker-dropdown.datepicker-orient-bottom:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #ffffff transparent;
  top: -6px;
  left: 6px; }

/*------------------------------------------------------------------------*/
/*--------------------------- HTML EDITOR --------------------------------*/
/*------------------------------------------------------------------------*/
ul.wysihtml5-toolbar > li {
  display: none;
  margin-bottom: 0; }
  ul.wysihtml5-toolbar > li:nth-child(2) {
    display: block; }

textarea.wysihtml {
  width: 100%;
  height: 240px;
  border: 0;
  padding: 0px 40px 0px 23px;
  line-height: 24px;
  border-bottom: 1px solid #eef1f5;
  margin-bottom: 5px; }
  textarea.wysihtml:focus {
    outline: none; }

.mail-form {
  background: #fff; }

.mail-header {
  border-left: 3px solid #f6b492;
  padding: 12px 10px 50px 1px;
  margin-bottom: -48px; }

.mail-header > div > div {
  float: left; }

.mail-header .mail-title {
  width: 10.6%;
  text-align: right;
  padding-right: 14px;
  color: #a5adb8;
  font-weight: 600; }

.mail-header .mail-value {
  width: 80%; }

.mail-header > div {
  padding-bottom: 1px;
  border-bottom: 1px solid #eef1f5;
  margin-bottom: 6px; }

.mail-header > div:after {
  content: '';
  display: table;
  clear: both; }

.mail-value textarea {
  outline: 0;
  border: 0;
  resize: none;
  width: 100%;
  padding: 0;
  height: 40px;
  line-height: 20px; }

.mail-value input[type=text] {
  border: 0;
  margin-bottom: 6px;
  padding: 0;
  box-shadow: none;
  color: #7a818a;
  width: 100%; }

.mail-value input[type=text]:focus {
  border: 0;
  outline: none; }

ul.wysihtml5-toolbar {
  margin-left: 6px; }

.mail-form button.btn {
  margin-left: 13px;
  padding-left: 15px;
  padding-right: 19px;
  margin-bottom: 10px; }

ul.wysihtml5-toolbar {
  margin-left: 6px;
  border-bottom: 1px solid #eef1f5;
  margin-bottom: 25px; }

ul.wysihtml5-toolbar a.btn {
  background: transparent;
  color: #000;
  font-size: 13px;
  padding: 7px 22px;
  margin-bottom: 10px; }

ul.wysihtml5-toolbar a.btn.wysihtml5-command-active {
  background: #EEF1F5;
  box-shadow: none; }

.mail-header > div:last-child .mail-value input[type=text] {
  font-weight: 600; }

/*---------------------------------------------------------------------*/
/*---------------------------- INLINE EDIT ----------------------------*/
/*---------------------------------------------------------------------*/
.skill, .fase {
  font-size: 20px; }

.inline-edit {
  border-bottom: 1px dashed #f7b797;
  cursor: pointer; }

.edit-block {
  display: inline; }
  .edit-block .edit-form {
    display: inline-block; }
    .edit-block .edit-form .btn {
      margin: 0;
      width: 38px;
      padding: 0;
      height: 34px;
      vertical-align: top; }
      .edit-block .edit-form .btn[type="button"] {
        background: #fa3c1d; }

/*---------------------------------------------------------------------*/
/*------------------------------- FASE --------------------------------*/
/*---------------------------------------------------------------------*/
.fase {
  font-weight: 600; }
  .fase > .pull-right {
    font-size: 14px;
    color: #a1a1a1;
    font-weight: 400;
    position: relative;
    top: 7px; }
    .fase > .pull-right .btn {
      height: 26px;
      width: 27px;
      font-size: 13px; }

.fase-block .block {
  margin-bottom: 11px; }

.field-container {
  padding-left: 35px;
  margin-bottom: 14px; }

.field {
  line-height: 30px; }
  .field .edit-block {
    display: inline-block;
    margin-top: 2px; }
    .field .edit-block .edit-form .btn {
      height: 27px;
      width: 27px;
      font-size: 12px; }
    .field .edit-block .edit-form input {
      height: 27px;
      margin: 0;
      line-height: 16px;
      vertical-align: top; }

.number {
  margin-right: 30px; }

.add-new-field {
  margin-left: 35px;
  padding: 6px 12px;
  font-weight: 600;
  margin-bottom: 33px; }

/*---------------------------------------------------------------------*/
/*----------------------------- MESSAGE -------------------------------*/
/*---------------------------------------------------------------------*/
.message {
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 100;
  color: #fff;
  background: #00B0CB;
  padding: 12px 20px;
  font-size: 14px;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-transition: 0.3s cubic-bezier(0.26, 0.69, 0.5, 1.63);
  -webkit-transition: 0.3s cubic-bezier(0.26, 0.69, 0.5, 1.63);
  transition: 0.3s cubic-bezier(0.26, 0.69, 0.5, 1.63); }
  .message.active {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  .message:after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    background: inherit;
    content: ''; }

/*------------------------------------------------------------------*/
/*----------------------------- TABLES -----------------------------*/
/*------------------------------------------------------------------*/
.table {
  background: #fff;
  display: table;
  font-size: 13px;
  padding: 0 5px;
  margin-bottom: 19px; }
  .table .f, .table .i {
    color: #de01ff;
    border: 1px solid;
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    text-transform: uppercase;
    line-height: 14px;
    font-size: 10px;
    font-weight: 600;
    margin-right: 5px; }
  .table .i {
    color: #ff9000; }
  .table a {
    color: #000; }
    .table a:hover {
      text-decoration: none;
      border-bottom: 2px solid; }
  .table .th .fa {
    margin-top: 3px;
    margin-left: 5px;
    margin-right: -4px;
    border: 0; }
  .table .th div:nth-child(3) .fa {
    float: right; }

.tr, .th {
  display: table-row;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  transition: 0.2s; }

.tr.active {
  background-color: #eaeaea; }

.tr > div, .th > div {
  display: table-cell;
  border-right: 1px solid #eef1f5;
  padding: 6px 15px; }

.tr > div:nth-child(3), .tr > div:nth-child(4), .tr > div:nth-child(5) {
  white-space: nowrap; }

.tr > div:nth-child(3) {
  padding-left: 3px;
  padding-right: 30px; }

.table .tr:last-child > div {
  padding-bottom: 26px; }

.th > div {
  border-bottom: 1px solid #eef1f5;
  padding: 7px 15px 5px;
  white-space: nowrap; }

.th > div:last-child, .tr > div:last-child {
  border-right: 0; }

.tr > div:first-child, .th > div:first-child {
  padding-left: 7px;
  padding-right: 7px; }

.tr > div:nth-child(2), .th > div:nth-child(2) {
  padding-left: 10px;
  padding-right: 8px; }

.tr.active + .tr.active > div, .tr.active + .tr:hover > div, .tr:hover + .tr.active > div {
  border-top: 1px solid #FFF;
  padding-top: 5px; }

.tr:hover > div {
  background-color: #f0f0f0; }

/*------------------------------------------------------------------*/
/*--------------------------- USER INFO ----------------------------*/
/*------------------------------------------------------------------*/
.user-info span:first-child {
  width: 177px;
  display: inline-block; }

.user-info span {
  line-height: 22px; }

.user-info {
  padding-bottom: 12px; }

/*------------------------------------------------------------------*/
/*------------------------- TABLE HEADER ---------------------------*/
/*------------------------------------------------------------------*/
.table-header {
  background: #fff;
  padding-top: 5px;
  padding-left: 9px;
  padding-right: 17px;
  border-left: 3px solid #f7bb9d;
  border-bottom: 1px solid #EEF1F5; }
  .table-header.blue-border {
    border-left-color: #00b0cb; }
  .table-header.red-border {
    border-left-color: #ff0f0f; }

.table-header:after {
  content: '';
  display: table;
  clear: both; }

.table-header h3 {
  display: inline-block;
  margin-top: 0;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 4px; }

.table-header .checkbox-wrapper {
  margin-right: 13px; }

.table-header + .table .tr:first-child > div {
  padding-top: 24px; }

.table-header + .table .tr > div:first-child {
  padding-right: 7px;
  width: 30px; }

.table-header + .table .tr > div:nth-child(2) {
  width: 39px; }

.table-header + .table .tr > div:nth-child(3) {
  padding-left: 18px; }

.table-header + .table .tr:last-child > div {
  padding-bottom: 17px; }

/*------------------------------------------------------------------*/
/*-------------------------- POOL TABLE ----------------------------*/
/*------------------------------------------------------------------*/
.table.pool-table > div > div:nth-child(1) {
  width: 30px; }

.table.pool-table > div > div:nth-child(2) {
  width: 43px; }

.table.pool-table > div > div:nth-child(3) {
  padding-left: 21px;
  width: 258px; }

.table.pool-table > div > div:nth-child(4) {
  width: 105px;
  padding-right: 5px; }

.table.pool-table > div > div:nth-child(5) {
  width: 135px; }

.table.pool-table .tr:last-child > div {
  padding-bottom: 6px; }

.table.pool-table {
  padding-bottom: 8px; }

/*------------------------------------------------------------------*/
/*----------------------------- FORMS ------------------------------*/
/*------------------------------------------------------------------*/
/*------------------------------------------------------------------*/
/*--------------------------- BUTTONS ------------------------------*/
/*------------------------------------------------------------------*/
.btn {
  background: #00b0cb;
  color: #fff;
  border-radius: 0;
  font-size: 15px;
  border: 0;
  font-weight: 400;
  margin-bottom: 20px;
  height: 36px;
  padding: 7px 29px;
  display: inline-block;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .btn + .btn {
    margin-left: 17px; }
  .btn:hover {
    background: #0091a7;
    color: #fff; }
  .btn.outlined {
    border: 1px solid;
    color: #00b0cb;
    background: transparent; }
  .btn.rounded {
    border-radius: 20px; }
  .btn.btn-circle {
    width: 39px;
    height: 39px;
    padding: 0; }
  .btn.btn-big {
    padding: 12px 21px;
    height: 47px;
    letter-spacing: 0.5px; }
  .btn:last-child {
    margin-right: 0; }

.btn-red {
  background-color: #fa3c1d; }
  .btn-red:hover {
    background-color: #e42405; }

.btn-grey {
  background-color: #99a2af; }
  .btn-grey:hover {
    background-color: #7f8b9b; }
  .btn-grey.outlined {
    border: 1px solid;
    color: #99a2af;
    background: transparent; }

.btn-orange {
  background-color: #F39769; }
  .btn-orange:hover {
    background-color: #f07a3f; }
  .btn-orange.outlined {
    border: 1px solid;
    color: #F39769;
    background: transparent; }

/*------------------------------------------------------------------*/
/*------------------------ INPUT FIELDS ----------------------------*/
/*------------------------------------------------------------------*/
.form-control::-webkit-input-placeholder {
  color: #000; }

.form-control::-moz-placeholder {
  color: #000; }

.form-control:-moz-placeholder {
  color: #000; }

.form-control:-ms-input-placeholder {
  color: #000; }

.form-control.bottom-bordered::-webkit-input-placeholder {
  line-height: 20px; }

.form-control.bottom-bordered::-moz-placeholder {
  line-height: 20px; }

.form-control.bottom-bordered:-moz-placeholder {
  line-height: 20px; }

.form-control.bottom-bordered:-ms-input-placeholder {
  line-height: 20px; }

.bottom-bordered {
  border: 1px solid #000;
  border-radius: 0;
  border-width: 0 0 1px 0px;
  background: transparent;
  box-shadow: none;
  padding: 0;
  line-height: 20px;
  padding-left: 3px;
  letter-spacing: -0.5px;
  margin-bottom: 24px;
  height: 38px; }
  .bottom-bordered:focus {
    box-shadow: none;
    border-color: #000; }

.form-horizontal .control-label {
  text-align: left;
  font-weight: 500; }

.form-control {
  border-radius: 0;
  box-shadow: none;
  color: #000; }
  .form-control:focus {
    border-color: #cbcbcb;
    box-shadow: none; }

.field-description {
  font-size: 11px;
  margin-left: 0;
  display: block;
  float: left;
  width: 21%;
  padding-left: 6px; }

.input-group-addon {
  border-radius: 0;
  background: transparent; }
  .input-group-addon .fa {
    width: 17px; }

input[type=email]:focus:invalid + .input-error {
  opacity: 1;
  height: 36px; }

label.error {
  position: absolute;
  left: 101%;
  width: 210px;
  top: 8px;
  font-weight: 500; }

.form-control.error, .error {
  color: #ff5500;
  border-color: #ff5500; }

.charpicker {
  cursor: pointer; }

/*------------------------------------------------------------------*/
/*--------------------------- CHECKBOX -----------------------------*/
/*------------------------------------------------------------------*/
.checkbox-rounded {
  opacity: 0; }
  .checkbox-rounded + span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    margin-left: -20px;
    border: 1px solid #cdcdcd;
    margin-top: 3px;
    cursor: pointer;
    position: relative;
    background: #fff;
    vertical-align: top;
    margin-right: 10px; }
    .checkbox-rounded + span:after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 6px;
      margin: 4px; }
  .checkbox-rounded:checked + span:after {
    background: #000; }

/*------------------------------------------------------------------*/
/*--------------------------- SELECTS ------------------------------*/
/*------------------------------------------------------------------*/
.select {
  display: inline-block;
  width: 100%;
  font-size: 13px;
  margin-right: 12px;
  position: relative;
  z-index: 1; }
  .select select {
    display: none; }
  .select.active .options {
    opacity: 1;
    visibility: visible; }

.active-option {
  background: #fff;
  border: 1px solid #cbcbcb;
  padding: 6px 17px 8px;
  cursor: pointer; }
  .active-option:after {
    content: "\f107";
    font-family: fontawesome;
    float: right;
    margin: 2px -5px 2px 0; }

.options {
  background: #fff;
  margin-top: 4px;
  border: 1px solid #cbcbcb;
  padding: 5px 2px;
  visibility: hidden;
  opacity: 0;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: absolute;
  top: 100%;
  width: 100%; }
  .options a {
    display: block;
    color: #000;
    padding: 2px 15px 3px;
    cursor: pointer; }
    .options a:hover {
      background: #eef1f5;
      text-decoration: none; }

/*------------------------------------------------------------------*/
/*------------------------- CHECKBOXES -----------------------------*/
/*------------------------------------------------------------------*/
.checkbox-wrapper {
  display: inline-block; }
  .checkbox-wrapper .custom-checkbox {
    opacity: 0;
    width: 18px;
    height: 18px;
    margin-right: -18px;
    vertical-align: top;
    cursor: pointer;
    margin-top: 0; }
    .checkbox-wrapper .custom-checkbox:indeterminate + span:after {
      content: "\f068";
      font-family: fontawesome;
      font-size: 11px; }
    .checkbox-wrapper .custom-checkbox:checked + span:after {
      content: "\f00c";
      font-family: fontawesome;
      font-size: 11px; }
  .checkbox-wrapper span {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #eef1f5;
    margin-top: 4px;
    vertical-align: top;
    border: 1px solid #cbcbcb;
    text-align: center;
    line-height: 15px;
    margin-top: 0; }

.form-horizontal .checkbox {
  font-weight: 400;
  margin-bottom: 8px;
  display: block; }
  .form-horizontal .checkbox .checkbox-wrapper {
    margin-right: 9px; }

.input-fields {
  float: left;
  width: 59%;
  position: relative; }

.form-horizontal .block {
  padding-bottom: 0px;
  margin-bottom: 27px; }

.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0; }
  .form-horizontal .form-group > label {
    width: 20%;
    float: left; }

.form-horizontal .radio-inline {
  border-right: 1px solid #cbcbcb;
  padding-top: 0;
  margin-top: 10px;
  padding-right: 10px;
  margin-bottom: 11px; }
  .form-horizontal .radio-inline:last-child {
    border: 0; }

/*------------------------------------------------------------------*/
/*------------------------ RADIO BUTTONS ---------------------------*/
/*------------------------------------------------------------------*/
input[type=radio] {
  opacity: 0;
  width: 18px;
  height: 18px;
  margin-top: 0; }

input[type=radio] + span {
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #cbcbcb;
  border-radius: 50%;
  margin-left: -20px;
  vertical-align: top; }

input[type=radio] + span:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: #7d7d7d;
  margin: 3px;
  border-radius: 50%;
  transition: 0.3s;
  transform: scale(0);
  opacity: 0; }

input[type=radio]:checked + span:after {
  opacity: 1;
  transform: scale(1); }

.radio-wrapper {
  display: inline-block;
  margin-right: 7px; }

/*------------------------------------------------------------------*/
/*-------------------------- TEXTAREAS -----------------------------*/
/*------------------------------------------------------------------*/
textarea.form-control {
  min-height: 130px;
  margin-bottom: 10px; }

/*------------------------------------------------------------------*/
/*----------------------------- FILE -------------------------------*/
/*------------------------------------------------------------------*/
.mail-header .add-files {
  float: right;
  margin: 3px 0 -19px 0;
  text-align: right; }
  .mail-header .add-files input {
    opacity: 0;
    width: 143px;
    height: 36px;
    margin-bottom: -36px;
    cursor: pointer;
    position: relative;
    z-index: 10; }
  .mail-header .add-files button {
    z-index: 11; }
  .mail-header .add-files span {
    cursor: pointer;
    display: inline-block;
    z-index: 100;
    position: relative;
    padding: 6px 11px 5px 9px;
    margin-right: 23px;
    font-size: 14px; }
    .mail-header .add-files span i {
      margin-right: 8px; }
  .mail-header .add-files p {
    display: inline-block;
    vertical-align: top;
    margin-top: 7px;
    margin-right: 10px; }

/*------------------------------------------------------------------*/
/*----------------------------- PAGES ------------------------------*/
/*------------------------------------------------------------------*/
/*------------------------------------------------------------------*/
/*-------------------------- LOGIN PAGE ----------------------------*/
/*------------------------------------------------------------------*/
body.login {
  background-image: url(../img/login-bg.jpg);
  background-size: cover;
  background-position: center;
  overflow: hidden; }

.login-form-container {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -257px 0 0 -157px;
  background: rgba(255, 255, 255, 0.6);
  padding: 18px 28px 10px;
  min-height: 515px;
  width: 315px; }
  .login-form-container .logo {
    margin-bottom: 27px;
    background: transparent;
    float: none;
    margin-left: -10px; }
  .login-form-container .btn {
    margin-bottom: 0;
    text-transform: uppercase;
    display: block;
    width: 100%;
    height: 41px; }
  .login-form-container .checkbox {
    margin-top: 35px; }
  .login-form-container .password-reset {
    color: #F39769;
    margin-bottom: 64px;
    display: block;
    margin-top: 48px;
    font-size: 12px;
    letter-spacing: 0px; }
  .login-form-container label {
    font-size: 13px; }
    .login-form-container label.error {
      position: static;
      text-align: center;
      width: 100%; }

.password-reset-form {
  visibility: hidden;
  opacity: 0;
  height: 0; }

.input-error {
  color: #ff0000;
  margin-bottom: 30px;
  display: block;
  font-size: 13px;
  text-align: center;
  opacity: 0;
  height: 0;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.reset-form .login-form {
  opacity: 0;
  visibility: hidden;
  height: 0; }

.reset-form #password-reset-form {
  opacity: 1;
  visibility: visible;
  height: auto; }

#success-text {
  text-align: center;
  margin-bottom: 20px;
  color: #ff0000;
  /*height: 0;*/
  overflow: hidden; }

/*------------------------------------------------------------------*/
/*------------------------- MEDIA QUERIES --------------------------*/
/*------------------------------------------------------------------*/
@media (max-width: 1024px) {
  .breadcrumbs ul {
    padding-left: 5px; }
  .account-nav {
    margin-right: 5px; }
  .form-horizontal .form-group > label, .input-fields, .field-description {
    width: 100%; }
  label.error {
    position: static; }
  .mail-header .mail-title {
    width: 24.6%; }
  .mail-header .mail-value {
    width: 75%; }
  .table-header + .table .tr > div {
    white-space: normal; }
  .account-nav li a {
    padding: 12px 5px 6px 0px; }
  .table {
    font-size: 12px; }
  .tr > div, .th > div, .table.pool-table > div > div:nth-child(3) {
    padding: 6px 5px; }
  .filter-options .select {
    width: 190px;
    margin-bottom: 14px; } }
