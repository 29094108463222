/*------------------------------------------------------------------*/
/*------------------------ INPUT FIELDS ----------------------------*/
/*------------------------------------------------------------------*/

.form-control::-webkit-input-placeholder {color:#000;}
.form-control::-moz-placeholder          {color:#000;}
.form-control:-moz-placeholder           {color:#000;}
.form-control:-ms-input-placeholder      {color:#000;}

.form-control.bottom-bordered::-webkit-input-placeholder {line-height: 20px;}
.form-control.bottom-bordered::-moz-placeholder          {line-height: 20px;}
.form-control.bottom-bordered:-moz-placeholder           {line-height: 20px;}
.form-control.bottom-bordered:-ms-input-placeholder      {line-height: 20px;}


.bottom-bordered {
    border: 1px solid #000;
    border-radius: 0;
    border-width: 0 0 1px 0px;
    background: transparent;
    box-shadow: none;
    padding: 0;
    line-height: 20px;
    padding-left: 3px;
    letter-spacing: -0.5px;
    margin-bottom: 24px;
    height: 38px;
    &:focus {
    	box-shadow: none;
    	border-color: #000;
    }
}

.form-horizontal .control-label {
    text-align: left;
    font-weight: 500;
}

.form-control {
    border-radius: 0;
    box-shadow: none;
    color: #000;
	&:focus {
	    border-color: #cbcbcb;
	    box-shadow: none;
	}
}


.field-description {
    font-size: 11px;
    margin-left: 0;
    display: block;
    float: left;
    width: 21%;
    padding-left: 6px;
}

.input-group-addon {
    border-radius: 0;
    background: transparent;
	.fa {
	    width: 17px;
	}
}

input[type=email]:focus:invalid + .input-error {
    opacity:1;
    height: 36px;
}

label.error {
    position: absolute;
    left: 101%;
    width: 210px;
    top: 8px;
    font-weight: 500;
}

.form-control.error, .error {
    color: #ff5500;
    border-color: #ff5500;
}
.charpicker {
    cursor: pointer;
}
/*------------------------------------------------------------------*/
/*--------------------------- CHECKBOX -----------------------------*/
/*------------------------------------------------------------------*/

.checkbox-rounded {
	opacity: 0;
	& + span {
	    display: inline-block;
	    width: 15px;
	    height: 15px;
	    border-radius: 10px;
	    margin-left: -20px;
	    border: 1px solid #cdcdcd;
	    margin-top: 3px;
	    cursor: pointer;
	    position: relative;
	    background: #fff;
	    vertical-align: top;
    	margin-right: 10px;
		&:after {
		    content: '';
		    display: block;
		    width: 5px;
		    height: 5px;
		    border-radius: 6px;
		    margin: 4px;
		}
	}
	&:checked + span:after {
		background: #000;
	}
}

/*------------------------------------------------------------------*/
/*--------------------------- SELECTS ------------------------------*/
/*------------------------------------------------------------------*/

.select {
    display: inline-block;
    width: 100%;
    font-size: 13px;
    margin-right: 12px;
    position: relative;
    z-index: 1;
    select {
    	display: none;
    }
    &.active .options {
	    opacity: 1;
	    visibility: visible;
    }
}


.active-option {
    background: #fff;
    border: 1px solid #cbcbcb;
    padding: 6px 17px 8px;
	cursor: pointer;
	&:after {
	    content: "\f107";
	    font-family: fontawesome;
	    float: right;
    	margin: 2px -5px 2px 0;
	}
}
.options {
    background: #fff;
    margin-top: 4px;
    border: 1px solid #cbcbcb;
    padding: 5px 2px;
    visibility: hidden;
    opacity: 0;
    @include transition(0.3s);
    position: absolute;
    top: 100%;
    width: 100%;
	a {
	    display: block;
	    color: #000;
	    padding: 2px 15px 3px;
	    cursor: pointer;
		&:hover {
		    background: #eef1f5;
		    text-decoration: none;
		}
	}
}



/*------------------------------------------------------------------*/
/*------------------------- CHECKBOXES -----------------------------*/
/*------------------------------------------------------------------*/

.checkbox-wrapper {
	display: inline-block;
	.custom-checkbox {
	    opacity: 0;
	    width: 18px;
	    height: 18px;
	    margin-right: -18px;
	    vertical-align: top;
    	cursor: pointer;
    	margin-top: 0;
		&:indeterminate + span:after {
		    content: "\f068";
		    font-family: fontawesome;
		    font-size: 11px;
		}
		&:checked + span:after {
		    content: "\f00c";
		    font-family: fontawesome;
		    font-size: 11px;
		}
	}
	span {
	    display: inline-block;
	    width: 18px;
	    height: 18px;
	    background: #eef1f5;
	    margin-top: 4px;
	    vertical-align: top;
	    border: 1px solid #cbcbcb;
	    text-align: center;
	    line-height: 15px;
    	margin-top: 0;
	}
}

.form-horizontal .checkbox {
    font-weight: 400;
    margin-bottom: 8px;
    display: block;
	.checkbox-wrapper {
	    margin-right: 9px;
	}
}

.input-fields {
    float: left;
    width: 59%;
    position: relative;
}

.form-horizontal {
	.block {
	    padding-bottom: 0px;
	    margin-bottom: 27px;
	}
	.form-group {
	    margin-right: 0;
	    margin-left: 0;
		&> label {
		    width: 20%;
		    float: left;
		}
	}


	.radio-inline {
	    border-right: 1px solid #cbcbcb;
	    padding-top: 0;
	    margin-top: 10px;
	    padding-right: 10px;
    	margin-bottom: 11px;
		&:last-child {
		    border: 0;
		}
	}

}

/*------------------------------------------------------------------*/
/*------------------------ RADIO BUTTONS ---------------------------*/
/*------------------------------------------------------------------*/

input[type=radio] {
    opacity: 0;
    width: 18px;
    height: 18px;
    margin-top: 0;
}

input[type=radio] + span {
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 1px solid #cbcbcb;
    border-radius: 50%;
    margin-left: -20px;
    vertical-align: top;
}

input[type=radio] + span:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #7d7d7d;
    margin: 3px;
    border-radius: 50%;
    transition: 0.3s;
    transform: scale(0);
    opacity: 0;
}

input[type=radio]:checked + span:after {
    opacity: 1;
    transform: scale(1);
}

.radio-wrapper {
    display: inline-block;
    margin-right: 7px;
}

/*------------------------------------------------------------------*/
/*-------------------------- TEXTAREAS -----------------------------*/
/*------------------------------------------------------------------*/


textarea.form-control {
    min-height: 130px;
    margin-bottom: 10px;
}


/*------------------------------------------------------------------*/
/*----------------------------- FILE -------------------------------*/
/*------------------------------------------------------------------*/

.mail-header .add-files {
    float: right;
    margin: 3px 0 -19px 0;
    text-align: right;
    input {
        opacity: 0;
        width: 143px;
        height: 36px;
        margin-bottom: -36px;
        cursor: pointer;
        position: relative;
        z-index: 10;
    }

    button {
        z-index: 11;
    }

    span {
        cursor: pointer;
        display: inline-block;
        z-index: 100;
        position: relative;
        padding: 6px 11px 5px 9px;
        margin-right: 23px;
        font-size: 14px;
        i {
            margin-right: 8px;
        }
    }
    p {
        display: inline-block;
        vertical-align: top;
        margin-top: 7px;
        margin-right: 10px;
    }
}
