/*------------------------------------------------------------------*/
/*-------------------------- MENU SPRITE ---------------------------*/
/*------------------------------------------------------------------*/

[class*=menu-icon-]:before {
    content: '';
    background-image: url(../img/sprite.png);
    display: inline-block;
    width: 40px;
    height: 26px;
}

.menu-icon-home:before {
  background-position: 0 0;
  width: 24px;
}

.menu-icon-wachtlijst:before {
  background-position: -50px 0;
  width: 24px;
}

.menu-icon-active-deelnemers:before {
  background-position: -100px 0;
  width: 24px;
}

.menu-icon-niet-active-deelnemers:before {
  background-position: -150px 0;
  width: 24px;
}

.menu-icon-uitgeschereven-deelnemers:before {
  background-position: -200px 0;
  width: 33px;
}

.menu-icon-lesmateriaal-lestijd:before {
  background-position: -250px 0;
  width: 24px;
}

.menu-icon-instructeur:before {
  background-position: -300px 0;
  width: 24px;
}

.menu-icon-deelnemer-invoeren:before {
  background-position: -350px 0;
  width: 24px;
}