/*------------------------------------------------------------------*/
/*----------------------------- BLOCKS -----------------------------*/
/*------------------------------------------------------------------*/


#content {
    float: left;
    margin-left: 0px;
    padding-top: 50px;
    width: 100%;
}

.container {
    width: auto;
    padding: 12px 26px 10px 19px;
    background: #eef1f5;
    margin-left: 143px;
    min-height: 700px;
}

.block {
    background-color: #fff;
    padding: 13px 13px 9px;
    margin-bottom: 13px;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.bordered {
	border-left: 3px solid $blue;
    &.border-orange {
        border-left-color: $orange; 
    }
}

.space {
	display: block;
	/*clear: both;*/
    height: 50px;
}

.h20 {
    height: 20px;
}

.h70 {
    height: 70px;
}

.h10 {
    height: 10px;
}

/*------------------------------------------------------------------*/
/*----------------------------- FILTER -----------------------------*/
/*------------------------------------------------------------------*/

.active-filter a {
    padding: 6px 22px 8px 11px;
    display: inline-block;
    border: 1px solid;
    border-radius: 30px;
    margin-right: 9px;
    border-color: $darkOrange;
    text-decoration: none;
    color: #000;
    font-size: 13px;
}

.active-filter a i {
    margin-right: 7px;
    color: $darkOrange;
}

.filter-options {
    margin-bottom: 19px;
    .active-option {
        padding: 9px 17px;
    }
    .select {
        width: 192px;
    }
    input[type='text'] {
        padding: 8px 16px;
        margin-right: 0;
    }
}

.filter-buttons .btn {
    margin-bottom: 14px;
}



/*------------------------------------------------------------------------*/
/*----------------------------- CALENDAR ---------------------------------*/
/*------------------------------------------------------------------------*/

.datepicker.datepicker-dropdown {
    border-radius: 0;
    box-shadow: 5px 3px 0 0 rgba(0, 0, 0, 0.1);
    margin-top: 300px;
}
.datepicker table tr td {
    &.day:hover {
        border-radius: 0;
    }

    &.active.active {
        border-radius: 0;
        background: #00B0CB;
    }
}
.datepicker-dropdown.datepicker-orient-bottom:before {
    top: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent #D9D9D9 transparent;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent #ffffff transparent;
    top: -6px;
    left: 6px;
}

/*------------------------------------------------------------------------*/
/*--------------------------- HTML EDITOR --------------------------------*/
/*------------------------------------------------------------------------*/


ul.wysihtml5-toolbar > li {
    display: none;
    margin-bottom: 0;
    &:nth-child(2) {
        display: block;
    }
}


textarea.wysihtml {    
    width: 100%;
    height: 240px;
    border: 0;
    padding: 0px 40px 0px 23px;
    line-height: 24px;
    border-bottom: 1px solid #eef1f5;
    margin-bottom: 5px;
    &:focus {
        outline:none;
    }
}

.mail-form {
    background: #fff;
}

.mail-header {
    border-left: 3px solid #f6b492;
    padding: 12px 10px 50px 1px;
    margin-bottom: -48px;
}

.mail-header > div > div {
    float: left;
}

.mail-header .mail-title {
    width: 10.6%;
    text-align: right;
    padding-right: 14px;
    color: #a5adb8;
    font-weight: 600;
}

.mail-header .mail-value {
    width: 80%;
}

.mail-header > div {
    padding-bottom: 1px;
    border-bottom: 1px solid #eef1f5;
    margin-bottom: 6px;
}

.mail-header > div:after {
    content: '';
    display: table;
    clear: both;
}

.mail-value textarea {
    outline: 0;
    border: 0;
    resize: none;
    width: 100%;
    padding: 0;
    height: 40px;
    line-height: 20px;
}

.mail-value input[type=text] {
    border: 0;
    margin-bottom: 6px;
    padding: 0;
    box-shadow: none;
    color: #7a818a;
    width: 100%;
}

.mail-value input[type=text]:focus {
    border: 0;
    outline: none;
}
ul.wysihtml5-toolbar {
    margin-left: 6px;
}

.mail-form button.btn {
    margin-left: 13px;
    padding-left: 15px;
    padding-right: 19px;
    margin-bottom: 10px;
}
ul.wysihtml5-toolbar {
    margin-left: 6px;
    border-bottom: 1px solid #eef1f5;
    margin-bottom: 25px;
}
ul.wysihtml5-toolbar a.btn {
    background: transparent;
    color: #000;
    font-size: 13px;
    padding: 7px 22px;
    margin-bottom: 10px;
}
ul.wysihtml5-toolbar a.btn.wysihtml5-command-active {
    background: #EEF1F5;
    box-shadow: none;
}

.mail-header > div:last-child .mail-value input[type=text] {
    font-weight: 600;
}

/*---------------------------------------------------------------------*/
/*---------------------------- INLINE EDIT ----------------------------*/
/*---------------------------------------------------------------------*/

.skill {
    font-size: 20px;
}

.inline-edit {
    border-bottom: 1px dashed #f7b797;
    cursor: pointer;
}

.edit-block {
    display: inline;
    .edit-form {
        display: inline-block;
        .btn {
            margin: 0;
            width: 38px;
            padding: 0;
            height: 34px;
            vertical-align: top;
            &[type="button"] {
                background: $red;
            }
        }
    }
}

/*---------------------------------------------------------------------*/
/*------------------------------- FASE --------------------------------*/
/*---------------------------------------------------------------------*/

.fase {
    font-weight: 600;
    @extend .skill;
    & > .pull-right {
        font-size: 14px;
        color: #a1a1a1;
        font-weight: 400;
        position: relative;
        top: 7px;
        .btn {
            height: 26px;
            width: 27px;
            font-size: 13px;
        }
    }
}

.fase-block .block {
    margin-bottom: 11px;
}

.field-container {
    padding-left: 35px;
    margin-bottom: 14px;
}

.field {
    line-height: 30px;
    .edit-block {
        display: inline-block;
        margin-top: 2px;
        .edit-form .btn {
            height: 27px;
            width: 27px;
            font-size: 12px;
        }
        .edit-form input {
            height: 27px;
            margin: 0;
            line-height: 16px;
            vertical-align: top;
        }
        
    }
}

.number {
    margin-right: 30px;
}

.add-new-field {
    margin-left: 35px;
    padding: 6px 12px;
    font-weight: 600;
    margin-bottom: 33px;
}

/*---------------------------------------------------------------------*/
/*----------------------------- MESSAGE -------------------------------*/
/*---------------------------------------------------------------------*/

.message {
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 100;
    color: #fff;
    background: #00B0CB;
    padding: 12px 20px;
    font-size: 14px;
    @include transform(translateX(100%));
    @include transition(0.3s cubic-bezier(0.26, 0.69, 0.5, 1.63));
    &.active {
        @include transform(translateX(0%));
    }
    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100px;
        background: inherit;
        content: '';
    }
}

