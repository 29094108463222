/*------------------------------------------------------------------*/
/*---------------------------- NAV BAR -----------------------------*/
/*------------------------------------------------------------------*/

.logo {
    float: left;
    background: #fff;
    padding: 6px 12px 6px 8px;
}

.nav-bar {
    background: #eef1f5;
    box-shadow: 0 0 12.9px 0 rgba(0,0,0,0.25);
    position: fixed;
    width: 100%;
    z-index: 5;
    &:after {
        display: table;
        content: '';
        clear: both;
    }
    a {
        color: #99a2af;
        text-decoration: none;
    }
}

.breadcrumbs {
    float: left;
    ul {
        padding-left: 22px;
        margin-bottom: 9px;
    }

    li {
        display: inline-block;
        padding: 18px 0  3px;
        .fa {
            margin: 0 12px;
            color: #000000;
        }

        a {
            font-size: 13px;
            letter-spacing: -0.5px;
        }
    }
}

.account-nav {
    float: right;
    margin: 7px 29px 5px 0;
    ul {
        margin-bottom: 0;
        padding: 0;
    }

    li {
        display: inline-block;
        a {
            font-size: 14px;
            font-weight: 600;
            border-right: 1px solid;
            padding: 12px 20px 6px 15px;
            display: inline-block;
            vertical-align: middle;
        }
        .fa {
            font-size: 20px;
            font-weight: 400;
            &:before {
                @include transition(0.3s);
            }
            &:hover:before {
                color: #646C77;
            }
        }

    }
}


/*------------------------------------------------------------------*/
/*-------------------------- NAV SIDEBAR ---------------------------*/
/*------------------------------------------------------------------*/

.nav-sidebar {
    float: left;
    width: 143px;
    background: #26344b;
    margin-top: 50px;
    margin-right: -100%;
}

.menu-sidebar {
    padding: 0;
    text-align: center;
    li {
        padding-top: 11px;
        position: relative;
        background: #26344B;
        list-style: none;
        &:hover {
            // background: #081730;
            background: #eef1f5;

            a {color: #081730}
        }
        &.active {
            // background-color: #081730;
            background: #eef1f5;

            a {color: #081730}
        }
        &:hover > ul {
            opacity: 1;
            visibility: visible;
        }
        ul {
            position: absolute;
            left: 100%;
            top: 0;
            padding: 0;
            width: 143px;
            z-index: 5;
            opacity: 0;
            visibility: hidden;
            @include transition(0.3s);
            li {
                padding: 0;
                background: #eef1f5;
                &:hover, a:hover {
                    background: #26344B;
                    color: #eef1f5;
                }
            }
        } 

        a {
            display: block;
            color: #b9c8e4;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.6px;
            padding: 10px 19px 14px;
            border-bottom: 1px solid #4d5d7c;
            text-decoration: none;
        }
        span {
            display: block;
        }
    }
}

