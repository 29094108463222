//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

div.dataTables_wrapper {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 14em);
  width: 100%;

  .filter {
    display: flex;
    flex-wrap: wrap;
  }

  .filter > div {
    flex: 1 1 auto;
  }

  div.dt-buttons {
    float: none;
    text-align: center;
    display: none;
  }

  div.dataTables_processing {
    z-index: 99;
  }

  .dataTables_scroll {
    display: flex;
    flex: 1 1 0;
    flex-flow: column nowrap;

    th {
      font-weight: normal;
      border-right: 1px solid #eef1f5;
      border-bottom: 1px solid #eef1f5;
    }

    .dataTables_scrollBody {
      flex: 1 1 0;

      tr:hover {
        background-color: #f0f0f0;
      }
    }

    td {
      border: none;
      border-right: 1px solid #eef1f5;
    }
  }
}

table tr.selected {
  background: #eaeaea;
}

table.dataTable thead .sorting_asc:after {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f106";
}

table.dataTable thead .sorting_desc:after {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
}

table.dataTable thead .sorting:after {
  content: "";
}

.dt-buttons .dt-button {
  font-size: 1.2em;
  line-height:39px;
}

form#mail {
  input, textarea {
    width: 100%;
  }
}

form .input-group.times input.time {
  width: 3em;
  float: none;
}

#children {
  a {
    color: #333;
  }
  
  a:hover {
    color: #333;
    text-decoration: underline;
  }
}

span.inline-edit:empty:before {
    content: '0';
}

/*@media (max-width: 1024px) {*/
  table.table.print {
      font-size: 11px;
  }
/*}*/

table > tbody {

  > tr.empty-tr {
    font-size: 0.9em;
    font-weight: bold;
    td {
      padding: 0;
      border: none;
    }
  }

  & > tr.empty-tr.column-5, & > tr.empty-tr.column-2 {
    font-size: 0.9em;
    > td {padding: 0;}
  }

  > tr.empty-tr + tr.empty-tr > td {
    padding: 0 !important;
  }

  > tr.empty-tr + tr.full-tr {
    td {border-top: 1px solid #222;}
  }

  & > tr.full-tr > td {
    font-size: 0.9em;
    padding: 0 .5em 0 0;
    box-sizing: border-box;
  }
}

.filter-options .select {
  margin-bottom: 14px;
}

.nav-bar {
  z-index: 15 !important;
}


.active-option .selected {
  background: #F9CCB9;
  border: 1px solid #F67B69;
}

.menu-sidebar li {
    padding-top: 11px;
    /*margin-top: -2px; /* nieuw */
    position: relative;
    background: #26344B;
    list-style: none; }

.menu-sidebar li.active {
      background-color: #EEF1F5; } 


.menu-sidebar .active li a {
      color: #4E5E7A;
      font-weight: 600;
    }

.filter-options {
  .active-option.selected, #global.active {
    background: #F9CCB9;
    border: 1px solid #F67B69;
  }
}

tr.group td {
  border-top: 2px solid black !important;
}