/*------------------------------------------------------------------*/
/*----------------------------- FONTS ------------------------------*/
/*------------------------------------------------------------------*/

html {
	height: 100%;
}
body {
	font-family: Open sans, Arial, Tahoma;
    font-size: 13px;
	color: #323232;
    background: #26344b;
}


.page-title {
    font-size: 20px;
    font-weight: 600;
    margin-top: 27px;
    margin-bottom: 17px;
}