/*------------------------------------------------------------------*/
/*----------------------------- TABLES -----------------------------*/
/*------------------------------------------------------------------*/

.table {
    background: #fff;
    display: table;
    font-size: 13px;
    padding: 0 5px;
    margin-bottom: 19px;
    .f {
	    color: #de01ff;
	    border: 1px solid;
	    width: 16px;
	    height: 16px;
	    display: inline-block;
	    border-radius: 50%;
	    text-align: center;
	    text-transform: uppercase;
	    line-height: 14px;
	    font-size: 10px;
	    font-weight: 600;
	    margin-right: 5px;
	}
	.i {
		@extend .f;
		color: #ff9000;
	}
	a {
		color: #000;
		&:hover {
			text-decoration:none;
    		border-bottom: 2px solid;
		}
	}
    .th .fa {
        margin-top: 3px;
        margin-left: 5px;
        margin-right: -4px;
        border: 0;
    }
    .th div:nth-child(3) .fa {
        float: right;
    }
}

.tr, .th {
    display: table-row;
    @include transition(0.2s);
}

.tr.active {
	background-color: #eaeaea;
}

.tr > div, .th > div {
    display: table-cell;
    border-right: 1px solid #eef1f5;
    padding: 6px 15px;
}

.tr > div:nth-child(3), .tr > div:nth-child(4), .tr > div:nth-child(5) {
    white-space: nowrap;
}

.tr > div:nth-child(3) {
    padding-left: 3px;
    padding-right: 30px;
}
.table .tr:last-child > div {
    padding-bottom: 26px;
}
.th > div {
    border-bottom: 1px solid #eef1f5;
    padding: 7px 15px 5px;
    white-space: nowrap;
}

.th > div:last-child, .tr > div:last-child {
    border-right: 0;
}

.tr > div:first-child, .th > div:first-child {
    padding-left: 7px;
    padding-right: 7px;
}

.tr > div:nth-child(2), .th > div:nth-child(2) {
    padding-left: 10px;
    padding-right: 8px;
}
.tr.active + .tr.active > div, .tr.active + .tr:hover > div, .tr:hover + .tr.active > div {
    border-top: 1px solid #FFF;
    padding-top: 5px;
}

.tr:hover > div {
    background-color: #f0f0f0;
}


/*------------------------------------------------------------------*/
/*--------------------------- USER INFO ----------------------------*/
/*------------------------------------------------------------------*/

.user-info span:first-child {
    width: 177px;
    display: inline-block;
}

.user-info span {
    line-height: 22px;
}

.user-info {
    padding-bottom: 12px;
}

/*------------------------------------------------------------------*/
/*------------------------- TABLE HEADER ---------------------------*/
/*------------------------------------------------------------------*/


.table-header {
    background: #fff;
    padding-top: 5px;
    padding-left: 9px;
    padding-right: 17px;
    border-left: 3px solid #f7bb9d;
    border-bottom: 1px solid #EEF1F5;
    &.blue-border {
    	border-left-color: $blue; 
    }
    &.red-border {
    	border-left-color: $border_red; 
    }
}

.table-header:after {
    content: '';
    display: table;
    clear: both;
}

.table-header h3 {
    display: inline-block;
    margin-top: 0;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 4px;
}

.table-header .checkbox-wrapper {
    margin-right: 13px;
}

.table-header + .table .tr:first-child > div {
    padding-top: 24px;
}

.table-header + .table .tr > div:first-child {
    padding-right: 7px;
    width: 30px;
}

.table-header + .table .tr > div:nth-child(2) {
    width: 39px;
}

.table-header + .table .tr > div:nth-child(3) {
    padding-left: 18px;
}

.table-header + .table .tr:last-child > div {
    padding-bottom: 17px;
}

/*------------------------------------------------------------------*/
/*-------------------------- POOL TABLE ----------------------------*/
/*------------------------------------------------------------------*/

.table.pool-table > div > div:nth-child(1) {
    width: 30px;
}

.table.pool-table > div > div:nth-child(2) {
    width: 43px;
}

.table.pool-table > div > div:nth-child(3) {
    padding-left: 21px;
    width: 258px;
}

.table.pool-table > div > div:nth-child(4) {
    width: 105px;
    padding-right: 5px;
}

.table.pool-table > div > div:nth-child(5) {
    width: 135px;
}
.table.pool-table .tr:last-child > div {
    padding-bottom: 6px;
}

.table.pool-table {
    padding-bottom: 8px;
}