/*------------------------------------------------------------------*/
/*--------------------------- BUTTONS ------------------------------*/
/*------------------------------------------------------------------*/

.btn {
    background: $blue;
    color: #fff;
    border-radius: 0;
    font-size: 15px;
    border: 0;
    font-weight: 400;
    margin-bottom: 20px;
    height: 36px;
    padding: 7px 29px;
    display: inline-block;
    @include transition(0.3s);
    & + .btn {
	    margin-left: 17px;
	}
    &:hover {
    	background: darken($blue, 7%);
    	color: #fff;
    }
    &.outlined {
	    border: 1px solid;
	    color: #00b0cb;
	    background: transparent;
	}
	&.rounded {
	    border-radius: 20px;
	}
	&.btn-circle {
		width: 39px;
		height: 39px;
		padding: 0;
	}
	&.btn-big {
	    padding: 12px 21px;
	    height: 47px;
	    letter-spacing: 0.5px;
	}
	&:last-child {
		margin-right: 0;
	}
}

.btn-red {
	background-color: $red;
	&:hover {
		background-color: darken($red, 9%);
	}
}

.btn-grey {
	background-color: $grey;
	&:hover {
		background-color: darken($grey, 9%);
	}
    &.outlined {
	    border: 1px solid;
	    color: $grey;
	    background: transparent;
	}
}

.btn-orange {
	background-color: $orange;
	&:hover {
		background-color: darken($orange, 9%);
	}
    &.outlined {
	    border: 1px solid;
	    color: $orange;
	    background: transparent;
	}
}